<template>
  <div id="services">
    <!-- Services section  -->
    <div class="relative bg-white py-16 sm:py-24 lg:py-32">
      <div
        class="
          mx-auto
          max-w-md
          px-4
          text-center
          sm:max-w-3xl
          sm:px-6
          lg:px-8
          lg:max-w-7xl
        "
      >
        <h2
          class="
            text-base
            font-semibold
            tracking-wider
            text-emiyablue
            uppercase
          "
        >
          Services
        </h2>
        <p
          class="
            mt-2
            text-3xl
            font-extrabold
            text-gray-900
            tracking-tight
            sm:text-4xl
          "
        >
          Your web and mobile app needs in one place
        </p>
        <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          From personal and brochure web pages, to e-commerce web apps, to iOS
          mobile apps, your design, development, and management is handled. No
          overcomplicated, costly solutions; you're covered: small business to
          small business, person to person.
        </p>
        <div class="mt-12">
          <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <div class="pt-6">
              <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div class="-mt-6">
                  <div>
                    <span
                      class="
                        inline-flex
                        items-center
                        justify-center
                        p-3
                        bg-gradient-to-r
                        from-emiyablue80
                        to-emiyablue
                        rounded-md
                        shadow-lg
                      "
                    >
                      <!-- Heroicon name: cloud-upload -->
                      <svg
                        class="h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                      </svg>
                    </span>
                  </div>
                  <h3
                    class="
                      mt-8
                      text-lg
                      font-medium
                      text-gray-900
                      tracking-tight
                    "
                  >
                    Cloud First
                  </h3>
                  <p class="mt-5 text-base text-gray-500">
                    AWS, Google Cloud, Netlify and others; we deploy to the best
                    solution, including containerization, for your needs for
                    quick, iterative app deployments as well as API updates.
                  </p>
                </div>
              </div>
            </div>

            <div class="pt-6">
              <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div class="-mt-6">
                  <div>
                    <span
                      class="
                        inline-flex
                        items-center
                        justify-center
                        p-3
                        bg-gradient-to-r
                        from-emiyablue80
                        to-emiyablue
                        rounded-md
                        shadow-lg
                      "
                    >
                      <!-- Heroicon name: lock-closed -->
                      <svg
                        class="h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                        />
                      </svg>
                    </span>
                  </div>
                  <h3
                    class="
                      mt-8
                      text-lg
                      font-medium
                      text-gray-900
                      tracking-tight
                    "
                  >
                    Security and Resiliency
                  </h3>
                  <p class="mt-5 text-base text-gray-500">
                    All apps and APIs are secured using best-practices end to
                    end with the privacy of you and your customers' data as our
                    first priority. Everything is built to be resilient with
                    backups and redundancies built to your needs.
                  </p>
                </div>
              </div>
            </div>

            <div class="pt-6">
              <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div class="-mt-6">
                  <div>
                    <span
                      class="
                        inline-flex
                        items-center
                        justify-center
                        p-3
                        bg-gradient-to-r
                        from-emiyablue80
                        to-emiyablue
                        rounded-md
                        shadow-lg
                      "
                    >
                      <!-- Heroicon name: refresh -->
                      <svg
                        class="h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        />
                      </svg>
                    </span>
                  </div>
                  <h3
                    class="
                      mt-8
                      text-lg
                      font-medium
                      text-gray-900
                      tracking-tight
                    "
                  >
                    Responsive and Accessible
                  </h3>
                  <p class="mt-5 text-base text-gray-500">
                    The modern web requires apps that look as good on mobile as
                    on desktop. Plus, by building with accessibility in mind at
                    every step, your web presence doesn't leave any of your
                    potential customers behind.
                  </p>
                </div>
              </div>
            </div>

            <div class="pt-6">
              <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div class="-mt-6">
                  <div>
                    <span
                      class="
                        inline-flex
                        items-center
                        justify-center
                        p-3
                        bg-gradient-to-r
                        from-emiyablue80
                        to-emiyablue
                        rounded-md
                        shadow-lg
                      "
                    >
                      <!-- Heroicon name: shield-check -->
                      <svg
                        class="h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                        />
                      </svg>
                    </span>
                  </div>
                  <h3
                    class="
                      mt-8
                      text-lg
                      font-medium
                      text-gray-900
                      tracking-tight
                    "
                  >
                    Modern Web and Mobile
                  </h3>
                  <p class="mt-5 text-base text-gray-500">
                    Your web apps are built using modern Vue.js or React single
                    page app (SPA) frameworks, styling and libraries. Your
                    existing site (Wordpress, Drupal) can also be maintained or
                    updated depending on your needs.
                  </p>
                </div>
              </div>
            </div>

            <div class="pt-6">
              <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div class="-mt-6">
                  <div>
                    <span
                      class="
                        inline-flex
                        items-center
                        justify-center
                        p-3
                        bg-gradient-to-r
                        from-emiyablue80
                        to-emiyablue
                        rounded-md
                        shadow-lg
                      "
                    >
                      <!-- Heroicon name: cog -->
                      <svg
                        class="h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </span>
                  </div>
                  <h3
                    class="
                      mt-8
                      text-lg
                      font-medium
                      text-gray-900
                      tracking-tight
                    "
                  >
                    Powerful APIs
                  </h3>
                  <p class="mt-5 text-base text-gray-500">
                    Your backend is built using modern, performant technologies,
                    such as Google's Go language and either RESTful or GraphQL
                    web services so your web and mobile apps can easily share
                    the same content and data.
                  </p>
                </div>
              </div>
            </div>

            <div class="pt-6">
              <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div class="-mt-6">
                  <div>
                    <span
                      class="
                        inline-flex
                        items-center
                        justify-center
                        p-3
                        bg-gradient-to-r
                        from-emiyablue80
                        to-emiyablue
                        rounded-md
                        shadow-lg
                      "
                    >
                      <!-- Heroicon name: server -->
                      <svg
                        class="h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"
                        />
                      </svg>
                    </span>
                  </div>
                  <h3
                    class="
                      mt-8
                      text-lg
                      font-medium
                      text-gray-900
                      tracking-tight
                    "
                  >
                    Modern, Performant Databases
                  </h3>
                  <p class="mt-5 text-base text-gray-500">
                    Cloud-first database storage built on plaforms suitable to
                    your app's needs with specialization in MongoDB, Google
                    Firebase and Realtime DB, Amazon Aurora, MySQL and
                    PostgreSQL.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>

<style scoped>
</style>