<template>
	<div id="contact">
		<!-- Contact page  -->
		<div class="relative overflow-hidden bg-white py-16 sm:py-24 lg:py-32">
			<div
				class="mx-auto max-w-md px-4 text-center text-xl font-bolt sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
				<span class="text-red-600">**</span>
				At this time, Emiya Consulting is not taking on new clients.
				Thank you for your interest.
				<span class="text-red-600">**</span>
			</div>
			<div
				class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
				<div>
					<iframe
						class="w-full aspect-square"
						src="https://docs.google.com/forms/d/e/1FAIpQLSdZ6q1so8qsomsPBUSusfgcqmrUqOknk5L4KP729rJGkrhytg/viewform?embedded=true"
						width="780"
						height="990"
						frameborder="0"
						marginheight="0"
						marginwidth="0">
						Loading…
					</iframe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AppContact",
	};
</script>

<style scoped></style>
